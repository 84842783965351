.square {
    padding-bottom: calc(100% - 2rem);
}

.rectangle {
    padding-bottom: 70%;
}

.rectangle-vertical {
    padding-bottom: 140%;
}

.rectangle-horizontal {
    padding-bottom: 35%;
}

.rectangle-60 {
    padding-bottom: 60%;
}

.rectangle-120 {
    padding-bottom: 120%;
}

@media #{$medium-and-down} {
    .rectangle,
    .rectangle-vertical,
    .rectangle-horizontal {
        padding-bottom: 70%;
    }
}

.block {
    position: relative;
    display: block;
    height: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .title {
        position: absolute;
        bottom: 0;
        padding: .5rem 0;
        font-size: 1.25rem;
        transform: translateY(100%);
        transition: none;

        &::after {
            position: relative;
            left: -1rem;
            content: ' →';
            opacity: 0;
            transition: all .4s $ease-out-circ;
        }
    }

    .cases {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 2rem;
        opacity: .75;
    }

    //@media #{$medium-and-down} {
    //    .content {
    //        background: transparent !important;
    //        opacity: 1;
    //    }
    //
    //    .title,
    //    .cases {
    //        display: inline-block;
    //        padding: .25rem;
    //        font-size: 1.25rem;
    //        color: $black;
    //        background: $white;
    //        opacity: 1;
    //    }
    //
    //    .cases {
    //        top: 2.75rem;
    //        right: auto;
    //        bottom: auto;
    //        left: .5rem;
    //    }
    //
    //    .title {
    //        margin-top: .5rem;
    //        margin-left: .5rem;
    //
    //        &::after {
    //            content: '';
    //        }
    //    }
    //}

    @media #{$large-and-up} {
        &:hover .title::after {
            left: 0;
            opacity: 1;
        }
    }
}

.blocks {
    .row {
        margin: 0;
    }

    .col {
        padding: 0;
    }

    .block {
        margin: 2rem;
    }

    &.no-margin .block {
        margin: 0;
    }

    .rectangle-120 {
        padding-bottom: calc(120% + 4rem);
    }
}
