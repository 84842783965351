.pricing {
    .info {
        display: inline-block;
        padding: 0 1rem;
        cursor: help;
        opacity: .85;

        &:hover {
            opacity: 1;
        }
    }

    h5 {
        margin-bottom: 1em;
    }

    .divider {
        margin: 1.5rem 0;
    }

    @media #{$medium-and-down} {
        .price-box {
            text-align: center;
        }

        .info {
            position: absolute;
        }
    }

    .margin-on-medium-and-down {
        margin-bottom: 5rem;
    }
}
