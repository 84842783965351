/*
[Master Stylesheet]
Project: Nana
Version: 1.0
Author: Yegor Borisenco <pimmey@pimmey.com>
Author profile: https://themeforest.net/user/pimmey

[Table of Contents]
00 Typography
1 Global styles
2 Navigation
3 Hero section
4 Blocks layout
5 FAQ section
6 Philosophy section
7 Team section
8 Journal section
9 Form elements
10 Expertise section
11 Pricing section
12 Modals
13 Slider
14 Toasts

[Color codes]
# Nana makes use of the beautiful Material design Color Palette - http://materializecss.com/color.html#palette
White: #fff
Black: #373737
Neon green: #6aff6e
Neon blue: #0bfcff
Neon yellow: #ffff82
Neon red: #fa4c2b
Neon orange: #ffce72

[Typography]
# Check out http://materializecss.com/typography.html for more details
body:       14.5px
p:          Responsive flow text (http://materializecss.com/typography.html#flow)
h1:         10rem/110%
h2:         4.5rem/110%
h3:         2.92rem/110%
h4:         2.28rem/110%
h5:         1.64rem/110%
*/

@import '../../node_modules/bourbon/app/assets/stylesheets/bourbon';

@import 'components/materialize-colors';
@import 'components/variables';

/*---------------------------------------------------------------------------------------

0 Typography

-----------------------------------------------------------------------------------------*/
@import 'components/typography';

/*---------------------------------------------------------------------------------------

1 Global styles

-----------------------------------------------------------------------------------------*/
@import 'components/global';

/*---------------------------------------------------------------------------------------

2 Navigation

-----------------------------------------------------------------------------------------*/
@import 'components/nav';

/*---------------------------------------------------------------------------------------

3 Hero section

-----------------------------------------------------------------------------------------*/
@import 'components/hero';

/*---------------------------------------------------------------------------------------

4 Blocks layout

-----------------------------------------------------------------------------------------*/
@import 'components/blocks';

/*---------------------------------------------------------------------------------------

5 FAQ section

-----------------------------------------------------------------------------------------*/
@import 'components/faq';

/*---------------------------------------------------------------------------------------

6 Philosophy section

-----------------------------------------------------------------------------------------*/
@import 'components/philosophy';

/*---------------------------------------------------------------------------------------

7 Team section

-----------------------------------------------------------------------------------------*/
@import 'components/team';

/*---------------------------------------------------------------------------------------

8 Journal section

-----------------------------------------------------------------------------------------*/
@import 'components/journal';

/*---------------------------------------------------------------------------------------

9 Form elements

-----------------------------------------------------------------------------------------*/
@import 'components/form';

/*---------------------------------------------------------------------------------------

10 Expertise section

-----------------------------------------------------------------------------------------*/
@import 'components/expertise';

/*---------------------------------------------------------------------------------------

11 Pricing section

-----------------------------------------------------------------------------------------*/
@import 'components/pricing';

/*---------------------------------------------------------------------------------------

12 Modals

-----------------------------------------------------------------------------------------*/
@import 'components/modal';

/*---------------------------------------------------------------------------------------

13 Slider

-----------------------------------------------------------------------------------------*/
@import 'components/slider';

/*---------------------------------------------------------------------------------------

14 Toasts

-----------------------------------------------------------------------------------------*/
@import 'components/toasts';
