.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;

    h1 {
        margin: 0;
    }

    h5 {
        color: $neon-yellow;
    }
}
