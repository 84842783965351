// Media query ranges
$small-screen-up: 601px;
$medium-screen-up: 993px;
$large-screen-up: 1201px;
$small-screen: 600px;
$medium-screen: 992px;
$large-screen: 1200px;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})";
$large-and-up: "only screen and (min-width : #{$medium-screen-up})";
$extra-large: "only screen and (min-width : #{$large-screen-up})";
$small-and-down: "only screen and (max-width : #{$small-screen})";
$medium-and-down: "only screen and (max-width : #{$medium-screen})";
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})";

// Global
$container-width: 80%;

// Custom colors
$white: #fff;
$black: #373737;

// Toast background colors
$error: color('red', 'accent-2');
$success: color('green', 'accent-3');

$neon-green: #6aff6e;
$neon-blue: #0bfcff;
$neon-yellow: #ffff82;
$neon-red: #fa4c2b;
$neon-orange: #ffce72;

$primary-dark: #0d1035 !default;
$primary-light: $neon-yellow !default;
