html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

body,
input,
textarea,
select,
button {
    -moz-font-feature-settings: 'liga', 'kern';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-kerning: normal;
}

h1,
h2,
nav .brand-logo {
    letter-spacing: .5rem;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: $primary-light;
}

h1 {
    font-size: 10rem;
    font-weight: 700;
    -webkit-text-stroke-width: 4px;

    @media #{$medium-and-down} {
        font-size: 7rem;
        -webkit-text-stroke-width: 3px;
    }

    @media #{$small-and-down} {
        font-size: 5rem;
        -webkit-text-stroke-width: 2px;
    }
}

h2 {
    font-size: 4.5rem;
    font-weight: 600;
    -webkit-text-stroke-width: 2px;

    @media #{$medium-and-down} {
        font-size: 3.5rem;
        -webkit-text-stroke-width: 3px;
    }

    @media #{$small-and-down} {
        font-size: 2.5rem;
        -webkit-text-stroke-width: 2px;
    }
}

nav .brand-logo {
    font-size: 3rem;
    font-weight: 800;
    -webkit-text-stroke-width: 2px;
}

.flow-text {
    font-weight: 400;
}

blockquote {
    padding: 0;
    font-size: 2.5rem;
    font-weight: 400;
    letter-spacing: -.01em;
    border: 0;

    .quote {
        margin-left: -18px;
    }

    @media #{$small-and-down} {
        font-size: 1.75rem;

        .quote {
            margin-left: -12px;
        }
    }
}
