@media only screen and (max-width: 992px) and (min-width: 601px) {
    #toast-container {
        right: 5%;
    }
}

.toast {
    display: none;
    height: auto;
    padding: 20px;
    line-height: 1.75rem;

    // A bit of a trickery in order to show only one toast at the time
    // Making sure the user won't freak out, when he sees 3 red thingies pop up
    &:first-of-type {
        display: block;
    }

    a {
        display: block;
        color: $white;
        text-decoration: underline;
    }

    &.error {
        background-color: $error;
    }

    &.success {
        color: color('shades', 'black');
        background-color: $success;
    }
}
